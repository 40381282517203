<template>
    <div
        class="tw-px-8"
        v-if="simulation">
        <div class="container-parent d-flex align-center justify-center tw-my-8">
            <h2 class="text-primary text-h4 font-weight-bold">Detalhes da Simulação</h2>
        </div>
        <div>
            <v-row>
                <v-col cols="12">
                    <p class="text-h6">Dados da Simulação</p>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    lg="6">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                :rules="[rules.required]"
                                v-model="simulationName"
                                label="Designação da Simulação"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                :rules="[rules.required, rules.email]"
                                v-model="simulationUserEmail"
                                label="Email do Utilizador"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <v-text-field
                                :rules="[rules.required]"
                                v-model="simulationUserName"
                                label="Nome do Utilizador"></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    lg="6">
                    <v-card color="primary">
                        <v-card-title class="text-h6">Fibras por Secção</v-card-title>
                        <div
                            class="tw-px-4 tw-py-4"
                            v-for="(yarn, index) in simulation.yarns"
                            :key="index">
                            <p>
                                <strong>Fio {{ yarn.name }}</strong
                                >: {{ isYarnValid(yarn) }}
                            </p>
                            <hr />
                        </div>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <p class="text-h6">Items da Simulação</p>
                </v-col>
            </v-row>
            <v-data-table
                :group-by="groupBy"
                :headers="headers1"
                :items="yarnItems">
                <template v-slot:group-header="{ item, columns, toggleGroup, isGroupOpen }">
                    <tr>
                        <td :colspan="columns.length">
                            <VBtn
                                size="small"
                                variant="text"
                                :icon="isGroupOpen(item) ? '$expand' : '$next'"
                                @click="toggleGroup(item)"></VBtn>

                            {{ item.items[0].raw.yarnName }}
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { ref, computed, nextTick, defineProps } from 'vue';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import { useAlert } from '@/composables/alert';
    import useRules from '@/composables/rules';
    import { useSimulationStore } from '@/store/simulation';

    const id = useSimulationStore().id;

    const rules = useRules();

    let groupBy = [
        {
            key: 'yarnId',
            order: 'asc',
        },
    ];

    let headers1 = [
        {
            title: 'Tipo de Fibra',
            key: 'raw_material.raw_material_type.name',
        },
        { title: 'Fibra', key: 'raw_material.name' },
        { title: 'Comprimento da Fibra', key: 'fiber_length.display' },
        { title: 'Tonalidade', key: 'color.hue.name' },
        { title: 'Cor', key: 'color.display' },
        { title: 'Percentagem', key: 'percentage' },
        { title: 'NE', key: 'NE' },
        { title: 'Nº Cabos', key: 'yarnCablesNumber' },
        { title: 'Tipo de Torção', key: 'yarnTwist' },
        //{ title: 'Actions', key: 'actions', sortable: false },
    ];

    let simulation = ref();
    let yarnItems = ref([]);

    let simulationName = ref('');
    let simulationUserEmail = ref('');
    let simulationUserName = ref('');


    function initialize() {
        let query = {
            populate: ['yarns.yarn_items.raw_material.raw_material_type', 'yarns.yarn_items.fiber_length', 'yarns.yarn_items.color.hue', 'yarns.twist'],
            pagination: {
                pageSize: 100,
                page: 1,
            },
        };

        axios
            .get(`/simulations/${id}?${qs.stringify(query)}`)
            .then((response) => {
                simulation.value = response.data.data;
                // calculate fibers per section for each yarn
                simulation.value.yarns.forEach((yarn: any) => {
                    let fibers_per_section = 0;
                    yarn.yarn_items.forEach((item: any) => {
                        item.fibers_per_section = (590.5 * 10 * item.percentage) / (item.fiber_length.DTEX * yarn.NE * 100);
                        fibers_per_section += item.fibers_per_section;
                        item.NE = yarn.NE;
                        item.yarnId = yarn.id;
                        item.yarnName = yarn.name;
                        item.yarnTwist = yarn.twist.name;
                        item.yarnCablesNumber = yarn.cables_number;

                        item.color = {
                            id: item.color.id,
                            name: item.color.name,
                            hue: {
                                id: item.color?.hue?.id ? item.color.hue.id : 0,
                                name: item.color?.hue?.name,
                            },
                            display: `${item.color.short_name} ${item.color.name}`,
                        };
                        item.fiber_length = {
                            id: item.fiber_length.id,
                            DTEX: item.fiber_length.DTEX,
                            MM: item.fiber_length.MM,
                            display: `${item.fiber_length.DTEX}DTEX ${item.fiber_length.MM}MM`,
                        };
                        yarnItems.value.push(item);
                    });
                    yarn.fibers_per_section = Math.round(fibers_per_section * 100) / 100;
                });

                simulationName.value = response.data.data.name;
                simulationUserName.value = response.data.data.user_name;
                simulationUserEmail.value = response.data.data.email;
            })
            .catch((error) => {
                console.log(error);
            });
    }

    initialize();

    const isYarnValid = (yarn: any) => {
        return yarn.fibers_per_section >= 58 ? 'Válido' : 'Inválido';
    };
</script>

<style scoped></style>

<template>
    <v-container>
        <div class="my-10 text-center">
            <v-row>
                <v-col cols="12">
                    <p class="text-primary text-h4 font-weight-bold">Simulador</p>
                </v-col>
            </v-row>
        </div>

        <v-stepper v-model="e1">
            <template v-slot:default="{ prev, next }">
                <v-stepper-header>
                    <v-stepper-item
                        :value="0"
                        :complete="checkComplete(0)"
                        :step="`Step 0`"
                        :title="`Dados Gerais`"
                        editable>
                    </v-stepper-item>
                    <v-divider></v-divider>
                    <template
                        v-for="(yarn, index) in steps.yarns"
                        :key="`${index + 1}-step`">
                        <v-stepper-item
                            :complete="checkComplete(index + 1)"
                            :step="`Step {{ index + 1 }}`"
                            :rules="[() => checkErrors(index + 1)]"
                            :title="`Fio ${index + 1}`"
                            :value="index + 1"
                            editable></v-stepper-item>

                        <v-divider></v-divider>
                    </template>
                </v-stepper-header>

                <v-stepper-window>
                    <v-stepper-window-item
                        :value="0"
                        title="Dados Gerais"
                        :key="`0-content`">
                        <v-row class="mt-2">
                            <v-col
                                cols="12"
                                sm="6"
                                md="6">
                                <v-text-field
                                    type="email"
                                    :rules="[rules.required, rules.email]"
                                    v-model="steps.fields.email"
                                    label="Insira o seu Email"></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6">
                                <v-text-field
                                    :rules="[rules.required]"
                                    v-model="steps.fields.user_name"
                                    label="Insira o seu nome"></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6">
                                <v-text-field
                                    :rules="[rules.required]"
                                    v-model="steps.fields.name"
                                    label="Designação da Simulação"></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                                md="6">
                                <v-text-field
                                    label="Nº Fios Diferentes"
                                    type="number"
                                    v-model="steps.fields.cables_number"
                                    :rules="[rules.required, (v) => rules.min(v, 1), (v) => rules.max(v, 5)]"
                                    min="1"
                                    max="5"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-stepper-window-item>
                    <template v-if="e1 != 0">
                        <v-stepper-window-item
                            v-for="(yarn, index) in steps.yarns"
                            :key="`${index + 1}-content`"
                            title="Fio {{ index + 1 }}"
                            :value="index + 1">
                            <v-stepper v-model="e2">
                                <template v-slot:default="{ prev, next }">
                                    <v-stepper-header>
                                        <v-stepper-item
                                            :value="0"
                                            :complete="e2 > 0"
                                            :step="`Step 0`"
                                            :title="`Dados Gerais`"
                                            editable>
                                        </v-stepper-item>
                                        <v-divider></v-divider>

                                        <template
                                            v-for="(item, index) in yarn.items"
                                            :key="`${index + 1}-step`">
                                            <v-stepper-item
                                                :complete="e2 > index + 1"
                                                :step="`Step {{ index + 1 }}`"
                                                :title="`Item ${index + 1}`"
                                                :value="index + 1"
                                                editable></v-stepper-item>

                                            <v-divider></v-divider>
                                        </template>
                                    </v-stepper-header>

                                    <v-stepper-window>
                                        <v-stepper-window-item
                                            :value="0"
                                            title="Dados Gerais"
                                            :key="`0-content`">
                                            <v-row class="mt-2">
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6">
                                                    <v-text-field
                                                        :rules="[rules.required]"
                                                        v-model="yarn.fields.name"
                                                        label="Insira o nome do Item"></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6">
                                                    <v-text-field
                                                        label="Quantidade de Cabos"
                                                        type="number"
                                                        v-model="yarn.fields.cables_number"
                                                        :rules="[rules.required, (v) => rules.min(v, 1), (v) => rules.max(v, 5)]"
                                                        min="1"
                                                        max="5"></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="6">
                                                    <v-text-field
                                                        label="NE"
                                                        type="number"
                                                        v-model="yarn.fields.NE"
                                                        :rules="[rules.required]"
                                                        min="1"
                                                        max="100"></v-text-field>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4">
                                                    <v-autocomplete
                                                        label="Tipo de Torção"
                                                        clearable
                                                        hide-details="auto"
                                                        v-model="yarn.fields.twist"
                                                        item-title="name"
                                                        item-value="id"
                                                        :rules="[rules.required]"
                                                        :items="twists"
                                                        variant="outlined"
                                                        return-object></v-autocomplete>
                                                </v-col>
                                            </v-row>
                                        </v-stepper-window-item>
                                        <v-stepper-window-item
                                            v-for="(item, index) in yarn.items"
                                            :key="`${index + 1}-step`">
                                            <v-row class="mt-2">
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4">
                                                    <v-autocomplete
                                                        @update:model-value="onUpdateRawMaterialType"
                                                        label="Tipo de Fibra"
                                                        clearable
                                                        hide-details="auto"
                                                        v-model="item.fields.rawMaterialType"
                                                        item-title="name"
                                                        item-value="id"
                                                        :rules="[rules.required]"
                                                        :items="rawMaterialTypes"
                                                        variant="outlined"
                                                        return-object></v-autocomplete>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4">
                                                    <v-autocomplete
                                                        label="Fibra"
                                                        clearable
                                                        hide-details="auto"
                                                        item-title="name"
                                                        item-value="id"
                                                        v-model="item.fields.rawMaterial"
                                                        :rules="[rules.required]"
                                                        :items="rawMaterials"
                                                        variant="outlined"
                                                        return-object></v-autocomplete>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4">
                                                    <v-autocomplete
                                                        label="Comprimento da Fibra"
                                                        clearable
                                                        hide-details="auto"
                                                        item-title="display"
                                                        item-value="id"
                                                        v-model="item.fields.fiberLength"
                                                        :rules="[rules.required]"
                                                        :items="fiberLengthsWithStrings"
                                                        variant="outlined"
                                                        return-object></v-autocomplete>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4">
                                                    <v-autocomplete
                                                        @update:model-value="onUpdateHue"
                                                        label="Tonalidade"
                                                        clearable
                                                        hide-details="auto"
                                                        item-title="name"
                                                        item-value="id"
                                                        v-model="item.fields.hue"
                                                        :rules="[rules.required]"
                                                        :items="hues"
                                                        variant="outlined"
                                                        return-object></v-autocomplete>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4">
                                                    <v-autocomplete
                                                        label="Cor"
                                                        clearable
                                                        hide-details="auto"
                                                        item-title="display"
                                                        :items="colorsWithStrings"
                                                        v-model="item.fields.color"
                                                        :rules="[rules.required]"
                                                        item-value="id"
                                                        variant="outlined"
                                                        return-object></v-autocomplete>
                                                </v-col>
                                                <v-col
                                                    cols="12"
                                                    sm="6"
                                                    md="4">
                                                    <v-text-field
                                                        label="Percentagem"
                                                        type="number"
                                                        :rules="[
                                                            rules.required,
                                                            (v) => rules.min(v, 1),
                                                            (v) => rules.max(v, 100),
                                                            (v) => (available_percentage(yarn) >= 0 ? true : 'Percentagem disponível excedida'),
                                                        ]"
                                                        v-model="item.fields.percentage"
                                                        min="1"
                                                        max="100"></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-stepper-window-item>
                                    </v-stepper-window>
                                </template>
                            </v-stepper>
                            <v-container>
                                <v-row>
                                    <v-col class="tw-flex tw-justify-end">
                                        <v-btn
                                            class="mr-4"
                                            height="50"
                                            color="primary"
                                            :disabled="e2 === 0"
                                            @click="previous_item">
                                            Anterior (Item)
                                        </v-btn>
                                        <v-btn
                                            height="50"
                                            class="mr-4"
                                            color="primary"
                                            :disabled="checkNextYarnItemRules"
                                            @click="next_item">
                                            Próximo (Item)
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-stepper-window-item>
                    </template>
                </v-stepper-window>
            </template>
        </v-stepper>
        <br />
        <v-container>
            <v-row>
                <v-col>
                    <v-btn
                        class="mr-4"
                        height="50"
                        color="primary"
                        :disabled="e1 === 0"
                        @click="previous">
                        Anterior (Fio)
                    </v-btn>
                    <v-btn
                        height="50"
                        class="mr-4"
                        color="primary"
                        :disabled="checkNextRules"
                        @click="next_">
                        Próximo (Fio)
                    </v-btn>
                    <v-btn
                        height="50"
                        color="white"
                        :disabled="checkCreateRules"
                        @click="finish">
                        Finalizar Simulação
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>
<script setup lang="ts">
    import { ref, computed, watch } from 'vue';
    import { RawMaterialType, RawMaterial, Hue, Color, FiberLength, Twist, Simulation, Yarn, YarnItem ,IStepper } from '@/types';
    import axios from '@/plugins/axios';
    import qs from 'qs';
    import { useAlert } from '@/composables/alert';
    import useRules from '@/composables/rules';
    import { useRouter } from 'vue-router';
    import { useSimulationStore } from '@/store/simulation';


    const rules = useRules();
    const { showAlert } = useAlert();
    const $router = useRouter();

    const e1 = ref(0);
    const e2 = ref(0);
    //const steps = ref(2);

    let steps = ref<any>({
        type: 'general',
        fields: {
            name: '',
            email: '',
            user_name: '',
            cables_number: 0,
        },
        yarns: [],
    });

    function next_() {
        if (e1.value == 0 && steps.value.yarns.length == 0) {
            for (let i = 0; i < steps.value.fields.cables_number; i++) {
                steps.value.yarns.push({
                    fields: {
                        name: '',
                        NE: 0,
                        twist: undefined,
                        cables_number: 0,
                    },
                    items: [],
                });
            }
        }
        e1.value++;
    }

    function previous() {
        e1.value--;
    }

    function next_item() {
        if (e2.value === steps.value.yarns[e1.value - 1].items.length) {
            steps.value.yarns[e1.value - 1].items.push({
                fields: {
                    rawMaterialType: undefined,
                    rawMaterial: undefined,
                    hue: undefined,
                    color: undefined,
                    fiberLength: undefined,
                    percentage: 0,
                },
            });
        }

        e2.value++;
    }

    function previous_item() {
        if (e2.value > 0) e2.value--;
    }

    function finish() {
        axios
            .post('/simulations', steps.value)
            .then((response) => {
                showAlert({
                    color: 'green',
                    message: 'Simulação criada com sucesso!',
                    variant: 'flat',
                });

                useSimulationStore().setId(response.data.data.info.id);

                $router.push({ name: 'SimulationResult' });
            })
            .catch((error) => {
                showAlert({
                    color: 'red',
                    message: 'Erro ao criar simulação!',
                    variant: 'flat',
                });
                console.log(error);
            });
    }

    let checkErrors = (index: number) => {
        if(index == 0) {
            return ![!steps.value.fields.name, !steps.value.fields.email, !steps.value.fields.user_name, !steps.value.fields.cables_number].some((rule) => rule);
        } else {
            return (
                ![!steps.value.yarns[index - 1].fields.name, !steps.value.yarns[index - 1].fields.NE, !steps.value.yarns[index - 1].fields.twist].some((rule) => rule) &&
                available_percentage(steps.value.yarns[index - 1]) == 0 &&
                steps.value.yarns[index - 1].items.length > 0 &&
                steps.value.yarns[index - 1].items.every((item: any) => {
                    return ![!item.fields.rawMaterialType, !item.fields.rawMaterial, !item.fields.hue, !item.fields.color, !item.fields.fiberLength, !item.fields.percentage].some((rule) => rule);
                })
            );
        }
    }

    let checkCreateRules = computed(() => {
        return (
            [!steps.value.fields.name, !steps.value.fields.email, !steps.value.fields.user_name, !steps.value.fields.cables_number].some((rule) => rule) ||
            steps.value.yarns.length === 0 ||
            steps.value.yarns.some((yarn: any) => {
                return (
                    [!yarn.fields.name, !yarn.fields.NE, !yarn.fields.twist, !yarn.fields.cables_number].some((rule) => rule) ||
                    available_percentage(yarn) != 0 ||
                    yarn.items.length === 0 ||
                    yarn.items.some((item: any) => {
                        return [!item.fields.rawMaterialType, !item.fields.rawMaterial, !item.fields.hue, !item.fields.color, !item.fields.fiberLength, !item.fields.percentage].some((rule) => rule);
                    })
                );
            })
        );
    });

    let checkNextYarnItemRules = computed(() => {
        if (e2.value === 0) return [!steps.value.yarns[e1.value - 1].fields.name, !steps.value.yarns[e1.value - 1].fields.NE, !steps.value.yarns[e1.value - 1].fields.twist].some((rule) => rule);
        else
            return (
                ([
                    !steps.value.yarns[e1.value - 1].items[e2.value - 1].fields.rawMaterialType,
                    !steps.value.yarns[e1.value - 1].items[e2.value - 1].fields.rawMaterial,
                    !steps.value.yarns[e1.value - 1].items[e2.value - 1].fields.hue,
                    !steps.value.yarns[e1.value - 1].items[e2.value - 1].fields.color,
                    !steps.value.yarns[e1.value - 1].items[e2.value - 1].fields.fiberLength,
                    !steps.value.yarns[e1.value - 1].items[e2.value - 1].fields.percentage,
                ].some((rule) => rule) ||
                    available_percentage(steps.value.yarns[e1.value - 1]) <= 0) &&
                e2.value >= steps.value.yarns[e1.value - 1].items.length
            );
    });

    let checkNextRules = computed(() => {
        if (e1.value === 0) return [!steps.value.fields.name, !steps.value.fields.email, !steps.value.fields.user_name, !steps.value.fields.cables_number].some((rule) => rule);
        else {
            return (
                [
                    !steps.value.yarns[e1.value - 1].fields.name,
                    !steps.value.yarns[e1.value - 1].fields.NE,
                    !steps.value.yarns[e1.value - 1].fields.twist,
                    !steps.value.yarns[e1.value - 1].fields.cables_number,
                ].some((rule) => rule) ||
                steps.value.yarns[e1.value - 1].items.length === 0 ||
                e1.value >= steps.value.yarns.length ||
                steps.value.yarns[e1.value - 1].items.some((item: any) => {
                    return (
                        [!item.fields.rawMaterialType, !item.fields.rawMaterial, !item.fields.hue, !item.fields.color, !item.fields.fiberLength, !item.fields.percentage].some((rule) => rule) ||
                        available_percentage(steps.value.yarns[e1.value - 1]) > 0
                    );
                })
            );
        }
    });

    function available_percentage(yarn: any) {
        let acc = 0;
        yarn.items.forEach((item: any) => {
            acc += Number(item?.fields?.percentage) || 0;
        });

        return 100 - acc;
    }

    let checkComplete = (index: number) => {
        if (index === 0) {
            return ![!steps.value.fields.name, !steps.value.fields.email, !steps.value.fields.user_name, !steps.value.fields.cables_number].some((rule) => rule);
        } else {
            return (
                ![!steps.value.yarns[index - 1].fields.name, !steps.value.yarns[index - 1].fields.NE, !steps.value.yarns[index - 1].fields.twist].some((rule) => rule) &&
                available_percentage(steps.value.yarns[index - 1]) == 0 &&
                steps.value.yarns[index - 1].items.length > 0 &&
                steps.value.yarns[index - 1].items.every((item: any) => {
                    return ![!item.fields.rawMaterialType, !item.fields.rawMaterial, !item.fields.hue, !item.fields.color, !item.fields.fiberLength, !item.fields.percentage].some((rule) => rule);
                })
            );
        }
    };

    // const disabled = computed(() => {
    //     return e1.value === 1 ? 'prev' : e1.value === steps.value.length ? 'next' : undefined;
    // });

    async function fetchRawMaterialTypes() {
        const { data } = await axios.get<RawMaterialType[]>('/raw-material-types');
        rawMaterialTypes.value = data.data;
    }

    const fiberLengthsWithStrings = computed(() => {
        return fiberLengths.value.map((fiberLength) => {
            return {
                ...fiberLength,
                display: `${fiberLength.DTEX}DTEX ${fiberLength.MM}MM`,
            };
        });
    });

    async function fetchRawMaterials(rawMaterialTypeId: number) {
        let query = {
            populate: ['raw_material_type'],
            ...(rawMaterialTypeId && {
                // if rawMaterialTypeId is not null, then add this object to the query
                filters: {
                    raw_material_type: rawMaterialTypeId,
                },
            }),
            pagination: {
                pageSize: 100,
                page: 1,
            },
        };
        query = qs.stringify(query);

        const { data } = await axios.get<RawMaterial[]>(`/raw-materials?${query}`);
        rawMaterials.value = data.data;
    }

    async function fetchHues() {
        const { data } = await axios.get<Hue[]>('/hues');
        hues.value = data.data;
    }

    async function fetchColors(hueId: number) {
        let query = {
            populate: ['hue'],
            ...(hueId && {
                // if hueId is not null, then add this object to the query
                filters: {
                    hue: hueId,
                },
            }),
            pagination: {
                pageSize: 100,
                page: 1,
            },
        };
        query = qs.stringify(query);

        const { data } = await axios.get<Color[]>(`/colors?${query}`);
        colors.value = data.data;
    }

    const colorsWithStrings = computed(() => {
        return colors.value.map((color) => {
            return {
                ...color,
                display: `${color.short_name} ${color.name}`,
            };
        });
    });

    async function fetchFiberLengths() {
        const { data } = await axios.get<FiberLength[]>('/fiber-lengths');
        fiberLengths.value = data.data;
    }

    async function fetchTwists() {
        const { data } = await axios.get<Twist[]>('/twists');
        twists.value = data.data;
    }

    let rawMaterialTypes = ref<RawMaterialType[]>([]);
    let rawMaterials = ref<RawMaterial[]>([]);
    let hues = ref<Hue[]>([]);
    let colors = ref<Color[]>([]);
    let twists = ref<Twist[]>([]);
    let fiberLengths = ref<FiberLength[]>([]);

    fetchRawMaterialTypes();
    //fetchRawMaterials();
    fetchHues();
    fetchFiberLengths();
    fetchTwists();

    function onUpdateRawMaterialType(value: number) {
        fetchRawMaterials(value);
    }

    function onUpdateHue(value: number) {
        fetchColors(value);
    }

    // watch e1.value to set e2 to 0
    watch(e1, () => {
        e2.value = 0;
    });
</script>
<style scoped></style>

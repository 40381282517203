// Composables
import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth';

import Home from '@/views/Home.vue';

// AUTH
import Auth from '@/views/Auth/Auth.vue';
import Login from '@/views/Auth/Login.vue';
import ForgotPassword from '@/views/Auth/ForgotPassword.vue';
import ResetPassword from '@/views/Auth/ResetPassword.vue';

// HOME
import CreateSimulation2 from '@/views/Home/CreateSimulation2.vue';
import SimulationResult from '@/views/Home/SimulationResult.vue';

import Test from '@/views/Test.vue';
import Test2 from '@/views/Test2.vue';


// BACKOFFICE
import Backoffice from '@/views/Backoffice/Backoffice.vue';
import Simulations from '@/views/Backoffice/Simulations.vue';
import SimulationDetail from '@/views/Backoffice/SimulationDetail.vue';

const routes = [
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        redirect: { name: 'Login' },
        beforeEnter: (to, from, next) => {
            const authStore = useAuthStore();
            if (authStore.isAuthenticated) {
                next({ name: 'Home' });
            } else {
                next();
            }
        },
        children: [
            {
                path: 'login',
                name: 'Login',
                component: Login,
            },
            {
                path: 'forgot-password',
                name: 'ForgotPassword',
                component: ForgotPassword,
            },
            {
                path: 'reset-password/:token',
                name: 'ResetPassword',
                component: ResetPassword,
            },
        ],
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/',
        component: Home,
        name: 'Home',
        meta: {
            authRequired: false,
        },
        children: [
            {
                path: '',
                name: 'CreateSimulation2',
                component: CreateSimulation2,
            },
            {
                path: 'simulation-result',
                name: 'SimulationResult',
                component: SimulationResult,
                props: true
            },
            {
                path: 'test',
                name: 'Test',
                component: Test,
            },
            {
                path: 'test2',
                name: 'Test2',
                component: Test2,
            }
        ]
    },
    {
        path: '/backoffice',
        component: Backoffice,
        name: 'Backoffice',
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: '',
                name: 'Simulations',
                component: Simulations,
            },
            {
                path: 'simulation-detail/:id',
                name: 'SimulationDetail',
                component: SimulationDetail,
                props: true,
            },
        ]
    },
] as Readonly<RouteRecordRaw[]>;

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const authRequired = to.matched.some((route) => route.meta.authRequired);
    if (authRequired) {
        const authStore = useAuthStore();
        if (!authStore.isAuthenticated) {
            next({ name: 'Login' });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;

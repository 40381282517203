<template>
    <TextileCone :color="color"></TextileCone>
</template>

<script setup lang="ts">
    import TextileCone from '@/components/TextileCone.vue';


    let color = "#6fe8d1";

</script>

<style scoped>

</style>
